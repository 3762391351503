import Swiper from 'swiper'


var fooo = document.querySelectorAll("[href='#']");

for (var i = 0; i < fooo.length; i++) {
    fooo[i].addEventListener('click', function(event) {
        event.preventDefault();
        //console.log(event);

        this.classList.toggle("active");
    });
}

var nav = document.querySelector('.navigation');


document.getElementById('mob-trigger').onclick = function() {
    this.classList.toggle("opened");
    nav.classList.toggle("opened");
}

// add custom js below
Holder.addTheme("dark", {
    bg: "#000",
    fg: "#aaa",
    size: 24,
    font: "courier",
    fontweight: "normal"
});

var mySwiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    loop: false,
    freeMode: true,
    slidesPerView: 'auto',
    observer: true,
    observeParents: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        hide: true,
    },
})
